<h3 class="header-text"><i class="pi pi-user"></i> <span class="header-text">{{siteTitle}}</span></h3>
<p-toast position="top-center"></p-toast>
<app-customer-submenu [activeSubmenuId]="3"></app-customer-submenu>

<div class="p-grid p-fluid customer-sub-content">
  <!--Create Mail Reminder -->
  <div class="p-col-12 p-md-6 padded-window">
    <div class="p-shadow-5 window-section">
      <div class="sub-window-header">
        <span class="sub-window-logo">
          <fa-icon [icon]="faCalendarPlus"></fa-icon>
        </span>
        <span class="sub-window-title">
          {{'createMailReminder.createTitle' | translate}}</span>
      </div>

      <div class="add-mailreminder-form">

        <div class="p-inputgroup reminder-input-title">
          <span class="p-inputgroup-addon">
            <fa-icon [icon]="faHeading"></fa-icon>
          </span>
          <input type="text" [(ngModel)]="addReminderTitle" pInputText
            placeholder="{{'createMailReminder.formAddTitle' | translate}}">
        </div>

        <div class="p-col-12 p-md-12">
          <angular-editor [(ngModel)]="addReminderText" [config]="editorConfig"
            [placeholder]="addReminderContentPlaceholder">
          </angular-editor>
        </div>

        <div class="p-field p-col-12 p-md-12">
          <p-calendar [(ngModel)]="addReminderDate" [showIcon]="true"></p-calendar>
        </div>

        <div class="p-col-12 p-md-12">
          <button class="add-button" pButton type="button" icon="pi pi-plus" iconPos="left"
            label="{{'createMailReminder.formAddButton' | translate}}" (click)="addReminder()"></button>
        </div>

      </div>
    </div>
  </div>

  <!--All Mail Reminders -->
  <div class="p-col-12 p-md-6 padded-window">
    <div class="p-shadow-5 window-section">
      <div class="sub-window-header">
        <span class="sub-window-logo">
          <fa-icon [icon]="faThList"></fa-icon>
        </span>
        <span class="sub-window-title">
          {{'createMailReminder.allTitle' | translate}}</span>
      </div>
      <p-dataView #mailRemindersList [value]="mailReminders" [paginator]="true" [rows]="9" filterBy="reminderTitle"
        layout="list">
        <ng-template pTemplate="header">
          <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <span class="p-input-icon-left p-mb-2 p-mb-md-0">
              <i class="pi pi-search"></i>
              <input type="search" pInputText placeholder="{{'createMailReminder.searchTitle' | translate}}"
                (input)="mailRemindersList.filter($event.target.value)" />
            </span>
          </div>
        </ng-template>
        <ng-template let-mailReminderItem pTemplate="listItem">
          <div class="p-col-12">
            <div class="mailreminder-list-item">
              <div class="mailreminder-title">{{mailReminderItem.reminderTitle}}</div>
              <div class="mailreminder-date"><span class="mailreminder-date-icon"><i
                    class="pi pi-calendar"></i></span>{{mailReminderItem.reminderDate | date: 'dd.MM.yyyy'}}</div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>
