<h3 class="header-text"><i class="pi pi-user"></i> <span
    class="header-text">{{'addCustomer.siteTitle' | translate}}</span></h3>
<p-toast position="top-center"></p-toast>
<div class="card">
  <div class="p-grid">
    <!--Left Side - Customer Details -->
    <div class="p-col-12 p-md-12 p-lg-12">
      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faSuitcase"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.companyNameTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.companyNameTitle' | translate}}"
                [(ngModel)]="addCompanyName">
            </div>
          </div>

          <div class="p-col-12 p-md-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faUser"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.forenameTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.forenameTitle' | translate}}"
                [(ngModel)]="addForename">
            </div>
          </div>

          <div class="p-col-12 p-md-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="farUser"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.surnameTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.surnameTitle' | translate}}"
                [(ngModel)]="addSurname">
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faRoad"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.streetTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.streetTitle' | translate}}"
                [(ngModel)]="addStreet">
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faMapMarkerAlt"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.postcodeTitle' | translate}}</span>
              </span>
              <p-inputNumber class="custom-field-full-width" [(ngModel)]="addPostcode" mode="decimal"
                [useGrouping]="false" placeholder="{{'addCustomer.postcodeTitle' | translate}}">
              </p-inputNumber>
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faCity"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.cityTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.cityTitle' | translate}}" [(ngModel)]="addCity">
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faGlobeAmericas"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.countryTitle' | translate}}</span>
              </span>
              <p-dropdown class="custom-field-full-width" [options]="countries" optionLabel="name"
                (ngModelChange)="updateSelectedCountry($event)" [(ngModel)]="selectedCountry" [showClear]="true" [filter]="true">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faAt"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.emailTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.emailTitle' | translate}}"
                [(ngModel)]="addEmail">
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faPhone"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.telephoneTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.telephoneTitle' | translate}}"
                [(ngModel)]="addTelephone">
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faInternetExplorer"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.websiteTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.websiteTitle' | translate}}"
                [(ngModel)]="addWebsite">
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faMoneyCheck"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.ibanTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.ibanTitle' | translate}}" [(ngModel)]="addIban">
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faUniversity"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.bicTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.bicTitle' | translate}}" [(ngModel)]="addBic">
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faMoneyCheckAlt"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.bankInformationTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.bankInformationTitle' | translate}}"
                [(ngModel)]="addBankInformation">
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faFacebook"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.facebookSocialTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.facebookSocialTitle' | translate}}"
                [(ngModel)]="addFacebook">
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faTwitter"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.twitterSocialTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.twitterSocialTitle' | translate}}"
                [(ngModel)]="addTwitter">
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faLinkedin"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.linkedInSocialTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.linkedInSocialTitle' | translate}}"
                [(ngModel)]="addLinkedin">
            </div>
          </div>
          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faXing"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.xingSocialTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.xingSocialTitle' | translate}}"
                [(ngModel)]="addXing">
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faShareAlt"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.socialMediaTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.socialMediaTitle' | translate}}"
                [(ngModel)]="addSocialMedia">
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faLanguage"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.languageTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'addCustomer.languageTitle' | translate}}"
                [(ngModel)]="addLanguage">
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faClock"></fa-icon><span
                  class="inputgroup-custom-text">{{'addCustomer.timezoneTitle' | translate}}</span>
              </span>
              <p-dropdown class="custom-field-full-width" [options]="timezones" optionLabel="timezone"
                (ngModelChange)="updateSelectedTimezone($event)" [(ngModel)]="addTimezone" [filter]="true"
                editable="true">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-md-12">
        <div class="p-inputgroup">
          <textarea rows="5" cols="30" pInputTextarea placeholder="{{'addCustomer.noteTitle' | translate}}"
            [(ngModel)]="addCustomerNote"></textarea>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-12">
      <button class="save-button" label=" " icon="" iconPos="left" pButton type="button" (click)="addCustomer()">
        <span>
          <fa-icon [icon]="faPlus"></fa-icon>
        </span><span class="save-button-label">{{'addCustomer.saveButton' | translate}}</span>
      </button>
    </div>
  </div>
</div>
