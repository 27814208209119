import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-customer-submenu',
  templateUrl: './customer-submenu.component.html',
  styleUrls: ['./customer-submenu.component.scss']
})
export class CustomerSubmenuComponent implements OnInit {
  @Input() activeSubmenuId: string;

  activeSubmenuItem: MenuItem;
  submenuItems: MenuItem[];

  //Translations
  submenuItemCustomer: string;
  submenuItemMessages: string;
  submenuItemNotes: string;
  submenuItemReminder: string;

  constructor(public translate: TranslateService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadSubMenuItems(params.id);
    });

  }

  loadSubMenuItems(selectedCustomerId) {
    this.translate.get(['editCustomerTab.customerTab', 'editCustomerTab.communicationTab', 'editCustomerTab.noteTab', 'editCustomerTab.mailReminderTab']).subscribe(translations => {
      this.submenuItemCustomer = translations['editCustomerTab.customerTab'];
      this.submenuItemMessages = translations['editCustomerTab.communicationTab'];
      this.submenuItemNotes = translations['editCustomerTab.noteTab'];
      this.submenuItemReminder = translations['editCustomerTab.mailReminderTab'];

      this.submenuItems = [
        { label: this.submenuItemCustomer, icon: 'pi pi-fw pi-user', url: 'customer/edit/' + selectedCustomerId },
        { label: this.submenuItemMessages, icon: 'pi pi-fw pi-comment', url: 'customer/messages/' + selectedCustomerId },
        { label: this.submenuItemNotes, icon: 'pi pi-fw pi-tags', url: 'customer/notes/' + selectedCustomerId },
        { label: this.submenuItemReminder, icon: 'pi pi-fw pi-clock', url: 'customer/reminder/' + selectedCustomerId },
      ];
      this.activeSubmenuItem = this.submenuItems[parseInt(this.activeSubmenuId)];
    });
  }

}
