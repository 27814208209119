<h3 class="header-text"><i class="pi pi-users"></i> <span class="header-text">{{'customers.siteTitle' | translate}}</span></h3>
<button class="add-button" pButton type="button" iconPos="left" label="{{'customers.addCustomerButtonText' | translate}}" (click)="openAddCustomerPage()"><i class="pi pi-plus"></i></button>

<p-toast position="top-center"></p-toast>
<div class="card all-customers-table">
    <p-table [value]="customers" [paginator]="true" [rows]="15" [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} / {totalRecords}" [rowsPerPageOptions]="[10,15,25,50,100,500]" styleClass="p-datatable-responsive-customer">
        <ng-template pTemplate="header">
            <tr>
                <th class="table-header">{{'customers.tableHeaderCompanyname' | translate}}</th>
                <th class="table-header">{{'customers.tableHeaderForename' | translate}}</th>
                <th class="table-header">{{'customers.tableHeaderSurname' | translate}}</th>
                <th class="table-header">{{'customers.tableHeaderEmail' | translate}}</th>
                <th class="table-header table-small">{{'customers.tableHeaderPhone' | translate}}</th>
                <th class="table-header">{{'customers.tableCellEditButton' | translate}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
            <tr>
                <td>{{customer.companyName}}</td>
                <td>{{customer.forename}}</td>
                <td>{{customer.surname}}</td>
                <td>{{customer.email}}</td>
                <td>{{customer.telephone}}</td>
                <td>
                    <button class="edit-button" pButton type="button" icon="pi pi-folder-open" iconPos="left" label="{{'customers.tableCellEditButton' | translate}}" (click)="editCustomer(customer._id)"></button>
                    <button class="delete-button" pButton type="button" icon="pi pi-times-circle" iconPos="left" label="{{'customers.tableCellDeleteButton' | translate}}" (click)="deleteCustomer(customer._id)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>