<h3 class="header-text"><i class="pi pi-user"></i> <span class="header-text">{{siteTitle}}</span></h3>
<p-toast position="top-center"></p-toast>
<app-customer-submenu [activeSubmenuId]="2"></app-customer-submenu>

<div class="p-grid p-fluid customer-sub-content">

  <!--All Customer Notes -->
  <div class="p-col-12 p-md-6 padded-window">
    <div class="p-shadow-5 window-section">
      <div class="sub-window-header">
        <span class="sub-window-logo">
          <fa-icon [icon]="faStickyNote"></fa-icon>
        </span>
        <span class="sub-window-title">
          {{'editCustomer.allCustomerNoteSectionTitle' | translate}}</span>
      </div>

      <p-dataView #customerNotesView [value]="customerNotes" [paginator]="true" [rows]="1" filterBy="description"
        [sortField]="customerNoteSortField" [sortOrder]="customerNoteSortOrder"  [emptyMessage]="customerNoteNoNotes" layout="list">
        <ng-template pTemplate="header">
          <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <p-dropdown [options]="customerNoteSortOptions"
              placeholder="{{'editCustomer.customerNoteSortPlaceHolder' | translate}}"
              (onChange)="onCustomerNoteSortChange($event)" styleClass="p-mb-2 p-mb-md-0">
            </p-dropdown>
            <span class="p-input-icon-left p-mb-2 p-mb-md-0">
              <i class="pi pi-search"></i>
              <input type="search" pInputText placeholder="{{'editCustomer.searchCustomerNotePlaceholder' | translate}}"
                (input)="customerNotesView.filter($event.target.value)">
            </span>
          </div>
        </ng-template>
        <ng-template let-customernote pTemplate="listItem">
          <div class="p-col-12">
            <div class="note-list-item">
              <div class="note-list-detail">
                <div class="note-title">
                  {{customernote.title}} </div>
                <div class="note-description">{{customernote.description}}</div>
                <div class="note-link" *ngIf="customernote.attachmentLink">
                  <i class="pi pi-external-link note-link-icon"></i><span class="note-link"><a
                      href="{{customernote.attachmentLink}}">{{customernote.attachmentLink}}</a></span>
                </div>
                <div class="note-date" *ngIf="customernote.createdDate"><i
                    class="pi pi-calendar-times note-date-icon"></i><span
                    class="createddate-text">{{customernote.createdDate | date: "dd/MM/yyyy HH:mm"}}</span>
                </div>
                <div class="note-date" *ngIf="customernote.updatedDate"><i
                    class="pi pi-calendar-times note-date-icon"></i><span
                    class="createddate-text">{{customernote.updatedDate | date: "dd/MM/yyyy HH:mm"}} {{'editCustomer.customerNoteUpdatedDateInfo' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">{{'editCustomer.customerNoteNoNotes' | translate}}.</td>
          </tr>
        </ng-template>  
      </p-dataView>

    </div>
  </div>

  <!-- Add New Customer Note -->
  <div class="p-col-12 p-md-6 padded-window">
    <div class="p-shadow-5 window-section">
      <div class="sub-window-header">
        <span class="sub-window-logo">
          <fa-icon [icon]="faNotesMedical"></fa-icon>
        </span>
        <span class="sub-window-title">
          {{'editCustomer.newCustomerNoteSectionTitle' | translate}}</span>
      </div>

      <div class="add-customernote-form">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><fa-icon [icon]="faHeading"></fa-icon><span
              class="inputgroup-custom-text">{{'editCustomer.customerNotesAddTitle' | translate}}</span></span>
          <input type="text" pInputText placeholder="{{'editCustomer.customerNotesAddTitle' | translate}}"
            [(ngModel)]="addCustomerNoteTitle">
        </div>
        <textarea rows="5" cols="30" pInputTextarea
          placeholder="{{'editCustomer.customerNotesAddDescription' | translate}}"
          [(ngModel)]="addCustomerNoteDescription"></textarea>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><fa-icon [icon]="faExternalLinkAlt"></fa-icon><span
              class="inputgroup-custom-text">{{'editCustomer.customerNotesAddUrl' | translate}}</span></span>
          <input type="text" pInputText placeholder="{{'editCustomer.customerNotesAddUrl' | translate}}"
            [(ngModel)]="addCustomerNoteUrl">
        </div>
        <button class="add-customernote-button" pButton type="button" icon="pi pi-plus" iconPos="left"
          label="{{'editCustomer.customerNotesAddButton' | translate}}" (click)="addNewCustomerNote()"></button>
      </div>
    </div>
  </div>

</div>
