<h3 class="header-text"><i class="pi pi-user"></i> <span class="header-text">{{siteTitle}}</span></h3>
<p-toast position="top-center"></p-toast>
<app-customer-submenu [activeSubmenuId]="0"></app-customer-submenu>

<div class="card edit-customers-table">
  <div class="p-grid">
    <!--Customer Details -->
    <div class="p-col-12 p-md-12 p-lg-12">
      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faSuitcase"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.companyNameTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.companyNameTitle' | translate}}"
                [(ngModel)]="editCompanyName">
            </div>
          </div>

          <div class="p-col-12 p-md-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faUser"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.forenameTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.forenameTitle' | translate}}"
                [(ngModel)]="editForename">
            </div>
          </div>

          <div class="p-col-12 p-md-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="farUser"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.surnameTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.surnameTitle' | translate}}"
                [(ngModel)]="editSurname">
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faRoad"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.streetTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.streetTitle' | translate}}"
                [(ngModel)]="editStreet">
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faMapMarkerAlt"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.postcodeTitle' | translate}}</span>
              </span>
              <p-inputNumber class="custom-field-full-width" [(ngModel)]="editPostcode" mode="decimal"
                [useGrouping]="false" placeholder="{{'editCustomer.postcodeTitle' | translate}}">
              </p-inputNumber>
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faCity"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.cityTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.cityTitle' | translate}}"
                [(ngModel)]="editCity">
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faGlobeAmericas"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.countryTitle' | translate}}</span>
              </span>
              <p-dropdown class="custom-field-full-width" [options]="countries" optionLabel="name"
                (ngModelChange)="updateSelectedCountry($event)" [(ngModel)]="selectedCountry" [filter]="true"
                editable="true">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faAt"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.emailTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.emailTitle' | translate}}"
                [(ngModel)]="editEmail">
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faPhone"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.telephoneTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.telephoneTitle' | translate}}"
                [(ngModel)]="editTelephone">
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faInternetExplorer"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.websiteTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.websiteTitle' | translate}}"
                [(ngModel)]="editWebsite">
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faMoneyCheck"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.ibanTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.ibanTitle' | translate}}"
                [(ngModel)]="editIban">
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faUniversity"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.bicTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.bicTitle' | translate}}" [(ngModel)]="editBic">
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faMoneyCheckAlt"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.bankInformationTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.bankInformationTitle' | translate}}"
                [(ngModel)]="editBankInformation">
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faFacebook"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.facebookSocialTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.facebookSocialTitle' | translate}}"
                [(ngModel)]="editFacebook">
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faTwitter"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.twitterSocialTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.twitterSocialTitle' | translate}}"
                [(ngModel)]="editTwitter">
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faLinkedin"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.linkedInSocialTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.linkedInSocialTitle' | translate}}"
                [(ngModel)]="editLinkedin">
            </div>
          </div>
          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faXing"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.xingSocialTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.xingSocialTitle' | translate}}"
                [(ngModel)]="editXing">
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faShareAlt"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.socialMediaTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.socialMediaTitle' | translate}}"
                [(ngModel)]="editSocialMedia">
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faLanguage"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.languageTitle' | translate}}</span>
              </span>
              <input type="text" pInputText placeholder="{{'editCustomer.languageTitle' | translate}}"
                [(ngModel)]="editLanguage">
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <fa-icon [icon]="faClock"></fa-icon><span
                  class="inputgroup-custom-text">{{'editCustomer.timezoneTitle' | translate}}</span>
              </span>
              <p-dropdown class="custom-field-full-width" [options]="timezones" optionLabel="timezone"
                (ngModelChange)="updateSelectedTimezone($event)" [(ngModel)]="selectedTimezone" [filter]="true"
                editable="true">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-md-12">
        <div class="p-inputgroup">
          <textarea rows="5" cols="30" pInputTextarea placeholder="{{'addCustomer.noteTitle' | translate}}"
            [(ngModel)]="editCustomerNote"></textarea>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-12">
      <button class="save-button" label=" " icon="" iconPos="left" pButton type="button" (click)="saveCustomer()">
        <span>
          <fa-icon [icon]="faCheck"></fa-icon>
        </span><span class="save-button-label">{{'editCustomer.saveButton' | translate}}</span>
      </button>
    </div>
  </div>
</div>