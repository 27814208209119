<h3 class="header-text"><i class="pi pi-user"></i> <span class="header-text">{{siteTitle}}</span></h3>
<p-toast position="top-center"></p-toast>

<app-customer-submenu [activeSubmenuId]="1"></app-customer-submenu>

<div class="p-grid p-fluid customer-sub-content">
  <!-- Communication Messages -->
  <div class="p-col-12 p-md-6 padded-window">
    <div class="p-shadow-5 window-section">
      <div class="sub-window-header">
        <span class="sub-window-logo">
          <fa-icon [icon]="faComments"></fa-icon>
        </span>
        <span class="sub-window-title">
          {{'editCustomer.communicationMessageSectionTitle' | translate}}</span>
      </div>

      <p-dataView #communicationMessagesView [value]="communicationMessages" [paginator]="true" [rows]="3"
        filterBy="message" [sortField]="communicationMessageSortField" [emptyMessage]="communicationMessagesNoMessages"
        [sortOrder]="communicationMessageSortOrder" layout="list">
        <ng-template pTemplate="header">
          <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <p-dropdown [options]="communicationMessageSortOptions"
              placeholder="{{'editCustomer.communicationMessagesSortPlaceHolder' | translate}}"
              (onChange)="onCommunicationMessageSortChange($event)" styleClass="p-mb-2 p-mb-md-0">
            </p-dropdown>
            <span class="p-input-icon-left p-mb-2 p-mb-md-0">
              <i class="pi pi-search"></i>
              <input type="search" pInputText placeholder="{{'editCustomer.searchCommunicationMessagePlaceholder' | translate}}"
                (input)="communicationMessagesView.filter($event.target.value)">
            </span>
          </div>
        </ng-template>
        <ng-template let-message pTemplate="listItem">
          <div class="p-col-12">
            <div class="message-list-item">
              <div class="message-list-detail card">
                <div class="message-communicationType-title" [style.borderColor]="communicationMessageTypesIdsObjects[message.communicationType].colorHex" [style.borderStyle]="'outset'" [style.color]="communicationMessageTypesIdsObjects[message.communicationType].colorHex">
                  {{communicationMessageTypesIdsObjects[message.communicationType].title}}  </div>
                <div class="message-description">{{message.message}}</div>
                <div class="message-date" [style.color]="communicationMessageTypesIdsObjects[message.communicationType].colorHex"><i class="pi pi-calendar-times message-date-icon"></i><span
                    class="createddate-text">{{message.createdDate | date: "dd/MM/yyyy HH:mm"}}</span>
                </div>
                <div class="message-tag" *ngIf="message.tag1" [style.color]="communicationMessageTypesIdsObjects[message.communicationType].colorHex">
                  <i class="pi pi-tag message-tag-icon"></i><span class="message-tag">{{message.tag1}}</span>
                  <span class="message-tag" *ngIf="message.tag2">{{message.tag2}}</span>
                  <span class="message-tag" *ngIf="message.tag3">{{message.tag3}}</span>
                  <span class="message-tag" *ngIf="message.tag4">{{message.tag4}}</span>
                  <span class="message-tag" *ngIf="message.tag5">{{message.tag5}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>

  <!-- Add New Communication Message -->
  <div class="p-col-12 p-md-6 padded-window">
    <div class="p-shadow-5 window-section">
      <div class="sub-window-header">
        <span class="sub-window-logo">
          <fa-icon [icon]="faCommentMedical"></fa-icon>
        </span>
        <span class="sub-window-title">
          {{'editCustomer.newCommunicationMessageSectionTitle' | translate}}</span>
      </div>

      <div class="add-communicationmessage-form">
        <p-dropdown [options]="communicationMessagesTypeArray" [(ngModel)]="newCommunicationMessageCommunicationType"
          [filter]="true" editable="true" [style]="{'margin-bottom': '5px'}" optionLabel="title">
          <ng-template let-communicationtype pTemplate="item">
            <div class="communicationtype-item">
              <div>{{communicationtype.title}}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <textarea rows="5" cols="30" pInputTextarea
          placeholder="{{'editCustomer.communicationMessageMessageTextarea' | translate}}"
          [(ngModel)]="newCommunicationMessageMessage" class="form-element"></textarea>
        <div class="p-inputgroup">
          <span class="input-form-desc">
            <fa-icon [icon]="faTags"></fa-icon><span
              class="inputgroup-custom-text">{{'editCustomer.communicationMessageTags' | translate}}</span>
          </span>
          <p-chips class="custom-field-chips-width" [(ngModel)]="newCommunicationMessageTags" separator="," max="4">
          </p-chips>
        </div>
        <button class="add-communicationmessage-button" pButton type="button" icon="pi pi-plus" iconPos="left"
          label="{{'customers.saveButton' | translate}}" (click)="addNewCommunicationMessage()"></button>
      </div>
    </div>
  </div>

</div>
