import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { AllcustomersComponent } from './components/allcustomers/allcustomers.component';
import { CommunicationtypesComponent } from './components/communicationtypes/communicationtypes.component';
import { EditCommunicationMessageComponent } from './components/edit-communication-message/edit-communication-message.component';
import { EditCommunicationtypeComponent } from './components/edit-communicationtype/edit-communicationtype.component';
import { EditCustomerNoteComponent } from './components/edit-customer-note/edit-customer-note.component';
import { EditCustomerComponent } from './components/edit-customer/edit-customer.component';
import { MailReminderComponent } from './components/mail-reminder/mail-reminder.component';


const routes: Routes = [
  { path: 'customers', component: AllcustomersComponent },
  { path: 'customer/add', component: AddCustomerComponent },
  { path: 'customer/edit/:id', component: EditCustomerComponent },
  { path: 'customer/messages/:id', component: EditCommunicationMessageComponent },
  { path: 'customer/notes/:id', component: EditCustomerNoteComponent },
  { path: 'customer/reminder/:id', component: MailReminderComponent },
  { path: 'communicationtypes', component: CommunicationtypesComponent },
  { path: 'communicationtype/edit/:id', component: EditCommunicationtypeComponent },
  { path: '', redirectTo: 'customers', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
